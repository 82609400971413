import { Formik } from "formik";
import React, { useCallback, useEffect, useState } from "react";
import * as Yup from "yup";
import DatePicker from "react-multi-date-picker";
import TimePicker from "react-multi-date-picker/plugins/time_picker";
import moment from "moment";
import "react-phone-input-2/lib/style.css";
import { useNavigate, useParams } from "react-router";
import { Progress } from "react-sweet-progress";
import { uploadFile } from "react-s3";
import { config } from "../../utils/index.js";
import { useDispatch, useSelector } from "react-redux";
import {
  AddClassReducer,
  ClassDetailReducer,
  clearClassDetail,
  SchoolListReducer,
} from "../slice.js";
import { toast } from "react-toastify";
import { MultiSelect } from "react-multi-select-component";
import { GetTeacher } from "../teacher/TeacherSlice.js";
import Loader from "./../../component/Loader";
import { FetchDisciplineList } from "../discipline/DisciplineSlice.js";
import {
  areaCodeListingSelector,
  FetchAreaCodeList,
} from "../areacode/slice.js";
import BackButton from "../../component/BackButton.jsx";

const options = [
  { label: "Sunday", value: "0" },
  { label: "Monday", value: "1" },
  { label: "Tuesday", value: "2" },
  { label: "Wednesday", value: "3" },
  { label: "Thursday", value: "4" },
  { label: "Friday", value: "5" },
  { label: "Saturday", value: "6" },
];

function AddClass() {
  const { sid, id } = useParams();

  const details = useSelector((state) => state.main.classDetail);
  const Schoollist = useSelector((state) => state.main.schools);
  const [time, setTime] = useState(0);
  const [error, setError] = useState("");
  const [isActive, setIsActive] = useState(false);
  const [ImageUp, setImage] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [schoolId, setSchoolId] = useState("");
  const [loading, setLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const [timeSlotArr, settimeSlotArr] = useState([
    {
      start_date: new Date(),
      end_date: new Date(),
      start_time: "",
      end_time: "",
      days: [],
      time_slot_for: [],
      time_slot_id: "",
    },
  ]);
  const [holidays, setHolidays] = useState([]);
  const teacherList = useSelector((state) => state.teacher.list);
  const disciplines = useSelector((state) => state.discipline.list);
  const areaList = useSelector(areaCodeListingSelector);
  const [areacode, setAreacode] = useState("");

  useEffect(() => {
    dispatch(FetchAreaCodeList({}));
  }, [dispatch]);

  const getDisciplines = useCallback(() => {
    dispatch(FetchDisciplineList({ page: 1, limit: 1000000 }));
  }, [dispatch]);

  useEffect(() => {
    getDisciplines();
  }, [getDisciplines]);

  const getDetails = useCallback(() => {
    setLoading(true);
    if (id) {
      dispatch(
        ClassDetailReducer({
          id: id,
          schoolId: sid,
        })
      ).then((res) => {
        setLoading(false);
        if (res?.payload?.code === 1) {
          setSchoolId(res?.payload?.data?.class_details?.schoolId?.toString());
          setImage(res?.payload?.data?.class_details?.image);
          let arr2 = res?.payload?.data?.class_details?.holiday_list
            ?.filter?.((it) => it?.date !== null)
            ?.map((item) => {
              return new Date(item?.date);
            });
          console.log(arr2);
          setHolidays(arr2);
          setAreacode(res?.payload?.data?.class_details?.areacode_details?.id);
          const arr =
            res?.payload?.data?.class_details?.time_slot_list_with_id?.map(
              (slotArr) => {
                return {
                  time_slot_id: slotArr?.id,
                  start_time: moment(
                    `${slotArr?.start_time}`,
                    "hh:mm:ss"
                  ).toISOString(),
                  end_time: moment(
                    `${slotArr?.end_time}`,
                    "hh:mm:ss"
                  ).toISOString(),
                  start_date: new Date(slotArr?.start_date),
                  end_date: new Date(slotArr?.end_date),

                  time_slot_for:
                    res?.payload?.data?.class_details?.class_type === "child"
                      ? ["child"]
                      : res?.payload?.data?.class_details?.class_type ===
                        "adult"
                      ? ["adult"]
                      : res?.payload?.data?.class_details?.class_type === "both"
                      ? ["child", "adult"]
                      : [],
                  days: slotArr?.day_list?.map((item) => {
                    return {
                      label:
                        item?.day === "0"
                          ? "Sunday"
                          : item?.day === "1"
                          ? "Monday"
                          : item?.day === "2"
                          ? "Tuesday"
                          : item?.day === "3"
                          ? "Wednesday"
                          : item?.day === "4"
                          ? "Thursday"
                          : item?.day === "5"
                          ? "Friday"
                          : item?.day === "6"
                          ? "Saturday"
                          : "",
                      value: item?.day,
                    };
                  }),
                };
              }
            );
          settimeSlotArr(arr);
        } else {
          toast?.error(res?.payload?.message);
        }
      });
    }
  }, [dispatch, id, sid]);

  useEffect(() => {
    if (id) {
      getDetails();
    }
  }, [getDetails, id]);

  useEffect(() => {
    dispatch(SchoolListReducer({ areacode }));
  }, [dispatch, areacode]);
  useEffect(() => {
    dispatch(GetTeacher({ page: 1, limit: 1000000 }));
  }, [dispatch]);

  useEffect(() => {
    let interval;
    if (isActive && time < 100) {
      interval = setInterval(() => {
        // if (time < 100)
        setTime((seconds) => (seconds < 99 ? seconds + 1 : seconds));
      }, 10);
    }
    return () => clearInterval(interval);
  }, [isActive, time]);

  useEffect(() => {
    return () => {
      dispatch(clearClassDetail(null));
    };
  }, [dispatch]);

  if (loading) {
    return (
      <article className="col-lg-9 articleClass">
        <Loader />
      </article>
    );
  } else {
    return (
      <article className="col-lg-9 articleClass">
        <div className="article-body store-bd-gy">
          <div className="row align-items-center col-rever">
            <BackButton />

            <div className="col-sm-12 col-md-12 col-lg-12 mb-3 text-center">
              <h3>{id ? "Edit" : "Add New"} Class</h3>
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-sm-12">
              <Formik
                initialValues={{
                  image: id ? details?.image : "",
                  name: id ? details?.name : "",
                  total_seats: id ? details?.total_seats : "",
                  child_price: id ? details?.child_price : "",
                  adult_price: id ? details?.adult_price : "",
                  about: id ? details?.about : "",
                  teachers: id ? details?.assigned_teacher?.teacher_id : "",
                  discipline: details?.discipline || "",
                  class_type:
                    details?.class_type?.toLowerCase() === "both"
                      ? [`adult`, `child`]
                      : details?.class_type?.length > 0
                      ? [`${details.class_type}`]
                      : [],
                  areacode: details?.areacode_details?.id || "",
                  schoolId: details?.school_details?.id || "",
                }}
                enableReinitialize={true}
                validationSchema={Yup.object({
                  image: Yup.string().notRequired(),

                  name: Yup.string().required("Required"),

                  total_seats: Yup.number()
                    .typeError("Kindly enter a valid number.")
                    .positive("Kindly enter a valid number."),
                  class_type: Yup.array()
                    .min(1, "Required")
                    .required("Required"),
                  areacode: Yup.string().required("Required"),
                  child_price: Yup.number().when("class_type", {
                    is: (value) =>
                      value.includes("child") || value.length === 2,
                    then: Yup.number()
                      .positive("Number only please")
                      .required("Number only please"),
                  }),

                  adult_price: Yup.number().when("class_type", {
                    is: (value) =>
                      value.includes("adult") || value.length === 2,
                    then: Yup.number()
                      .positive("Number only please")
                      .required("Number only please"),
                  }),

                  schoolId: Yup.string().required("Required"),
                  about: Yup.string(),
                  teachers: Yup.string().required("Required"),
                  discipline: Yup.string().required("Required"),
                })}
                onSubmit={(values, { setSubmitting }) => {
                  setSubmitting(true);

                  console.log(values, timeSlotArr, "Class values");
                  let checked = [...timeSlotArr]?.every(
                    (item) => item?.time_slot_for?.length !== 0
                  );

                  let hasDays = [...timeSlotArr]?.every(
                    (item) => item?.days?.length !== 0
                  );
                  if (checked && hasDays) {
                    let arr = [...timeSlotArr]?.map((item) => {
                      return {
                        start_date: moment(item?.start_date).format(
                          "YYYY-MM-DD"
                        ),
                        end_date: moment(item?.end_date).format("YYYY-MM-DD"),
                        start_time: moment(item?.start_time).format("HH:mm"),
                        end_time: moment(item?.end_time).format("HH:mm"),
                        time_slot_for:
                          item?.time_slot_for?.length === 2
                            ? "both"
                            : item?.time_slot_for[0],
                        days: item?.days?.map((item) => item?.value),
                        time_slot_id: item?.time_slot_id,
                      };
                    });
                    let arr2 = holidays?.map((item) => {
                      console.log(item, "Holiday item");
                      let dat = item?.toISOString().substring(0, 10);
                      return dat;
                    });
                    console.log(arr2, "holidays");
                    dispatch(
                      AddClassReducer({
                        schoolId: values.schoolId,
                        name: values.name,
                        image: values.image,
                        discipline: values?.discipline,
                        total_seats: values.total_seats
                          ? values.total_seats
                          : "",
                        child_price: values.child_price || "",
                        class_type:
                          values?.class_type.length === 2
                            ? "both"
                            : values.class_type?.[0],
                        areacode: values?.areacode,
                        adult_price: values.adult_price || "",
                        about: values.about,
                        class_id: id ? id : "",
                        time_slots: JSON.stringify(arr),
                        holidays: arr2?.length > 0 ? JSON.stringify(arr2) : [],
                        teacher_id: values.teachers,
                      })
                    ).then((res) => {
                      setSubmitting(false);

                      if (res?.payload?.code === 1) {
                        toast.success(res?.payload?.message);
                        navigate("/class", { replace: true });
                      } else {
                        toast?.error(res?.payload?.message);
                      }
                    });
                  } else {
                    setError("Kindly Fill All Details.");
                  }
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                  setFormikState,
                  setFieldValue,
                  getFieldProps,
                  /* and other goodies */
                }) => (
                  <form
                    className="form-style"
                    style={{
                      maxWidth: "1000px",
                    }}
                    onSubmit={handleSubmit}
                  >
                    <div className="row">
                      <div className="col-sm-12 col-md-12 mb-4">
                        <div className="form-group text-center">
                          <div className="upload-btn-wrapper">
                            <button className="btn">
                              {time > 0 && isActive ? (
                                <Progress
                                  type="circle"
                                  width={50}
                                  percent={time}
                                />
                              ) : (
                                <img
                                  src={
                                    values.image
                                      ? values.image
                                      : "assets/images/upload-event.png"
                                  }
                                  alt=""
                                />
                              )}

                              {time > 0 && isActive ? null : (
                                <div className="camera-icon">
                                  <i className="fa fa-camera"></i>
                                </div>
                              )}
                            </button>
                            <input
                              type="file"
                              accept="image/*"
                              onChange={(e) => {
                                if (e.target.files.length > 0) {
                                  const { files } = e.target;
                                  setIsActive(true);

                                  uploadFile(files[0], config)
                                    .then(async (data) => {
                                      setFieldValue("image", data.location);

                                      setTime(100);
                                      setIsActive(true);
                                      setTimeout(() => {
                                        setTime(0);
                                        setIsActive(false);
                                      }, 2000);
                                    })
                                    .catch((err) => console.error(err));
                                }
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-6 col-md-6 mb-4">
                        <label className="fw-bold">Class Name</label>
                        <div className="form-group">
                          <div className="input-container">
                            <input
                              type="text"
                              className="form-control pl-5"
                              placeholder="Class Name"
                              name="name"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.name}
                            />
                            <img
                              src="assets/images/event-name.png"
                              className="input-img"
                              alt=""
                            />
                          </div>
                          {errors.name && touched.name && (
                            <div className="text-red">{errors.name}</div>
                          )}
                        </div>
                      </div>
                      <div className="col-sm-6 col-md-6 mb-4">
                        <label className="fw-bold">Total Seats</label>
                        <div className="form-group">
                          <div className="input-container">
                            <input
                              type="number"
                              className="form-control pl-5"
                              placeholder="Total seats"
                              name="total_seats"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.total_seats}
                            />
                            <img
                              src="assets/images/total-seats.png"
                              className="input-img"
                              alt=""
                            />
                          </div>
                          {errors.total_seats && touched.total_seats && (
                            <div className="text-red">{errors.total_seats}</div>
                          )}
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-12 col-lg-6 mb-4">
                        <div className="form-group addUsers">
                          <label className="fw-bold">Class Type</label>
                          {errors.class_type && touched.class_type && (
                            <span className="text-red ms-1">
                              {errors.class_type}
                            </span>
                          )}
                          <div className="checkboxs">
                            <label>
                              <input
                                type="checkbox"
                                value="child"
                                checked={values?.class_type?.includes("child")}
                                name={`class_type`}
                                onChange={(e) => {
                                  let arr = [...values.class_type];
                                  let arr2 = [...timeSlotArr];
                                  let checked = e.target.checked;
                                  let isAdded = arr.includes(e.target.value);
                                  let index = arr.indexOf(e.target.value);
                                  if (checked && !isAdded) {
                                    arr.push(e.target.value);
                                    let arr3 = arr2.map((it) => {
                                      let arr4 = [...it?.time_slot_for];
                                      let classType =
                                        e.target.value === "child"
                                          ? "child"
                                          : e.target.value === "adult"
                                          ? "adult"
                                          : e.target.value === "both"
                                          ? "both"
                                          : "";
                                      let isClassTypeAdded =
                                        arr4.indexOf(classType);

                                      if (isClassTypeAdded === -1) {
                                        arr4.push(classType);
                                      }
                                      return {
                                        ...it,
                                        time_slot_for: arr4,
                                      };
                                    });
                                    settimeSlotArr(arr3);
                                    setFieldValue(`class_type`, arr);
                                  } else if (!checked && isAdded) {
                                    let arr3 = arr2.map((it) => {
                                      let arr4 = [...it?.time_slot_for];
                                      let classType =
                                        e.target.value === "child"
                                          ? "child"
                                          : e.target.value === "adult"
                                          ? "adult"
                                          : e.target.value === "both"
                                          ? "both"
                                          : "";
                                      let isClassTypeAdded =
                                        arr4.includes(classType);
                                      let classTypeIndex =
                                        arr4.indexOf(classType);

                                      if (isClassTypeAdded) {
                                        arr4.splice(classTypeIndex, 1);
                                      }
                                      return {
                                        ...it,
                                        time_slot_for: arr4,
                                      };
                                    });
                                    settimeSlotArr(arr3);
                                    arr.splice(index, 1);
                                    setFieldValue("child_price", "");
                                    setFieldValue(`class_type`, arr);
                                  }
                                }}
                                onBlur={handleBlur}
                              />
                              <div className="div-bg">
                                <p>
                                  <span>child</span>
                                </p>
                              </div>
                            </label>

                            <label>
                              <input
                                type="checkbox"
                                value="adult"
                                name={`class_type`}
                                onChange={(e) => {
                                  let arr = [...values.class_type];
                                  let arr2 = [...timeSlotArr];

                                  let checked = e.target.checked;
                                  let isAdded = arr.includes(e.target.value);
                                  let index = arr.indexOf(e.target.value);
                                  if (checked && !isAdded) {
                                    arr.push(e.target.value);
                                    setFieldValue(`class_type`, arr);
                                    let arr3 = arr2.map((it) => {
                                      let arr4 = [...it?.time_slot_for];
                                      let classType =
                                        e.target.value === "child"
                                          ? "child"
                                          : e.target.value === "adult"
                                          ? "adult"
                                          : e.target.value === "both"
                                          ? "both"
                                          : "";
                                      let isClassTypeAdded =
                                        arr4.includes(classType);

                                      if (!isClassTypeAdded) {
                                        arr4.push(classType);
                                      }
                                      return {
                                        ...it,
                                        time_slot_for: arr4,
                                      };
                                    });
                                    settimeSlotArr(arr3);
                                  } else if (!checked && isAdded) {
                                    let arr3 = arr2.map((it) => {
                                      let arr4 = [...it?.time_slot_for];
                                      let classType =
                                        e.target.value === "child"
                                          ? "child"
                                          : e.target.value === "adult"
                                          ? "adult"
                                          : e.target.value === "both"
                                          ? "both"
                                          : "";
                                      let isClassTypeAdded =
                                        arr4.includes(classType);
                                      let classTypeIndex =
                                        arr4.indexOf(classType);

                                      if (isClassTypeAdded) {
                                        arr4.splice(classTypeIndex, 1);
                                      }
                                      return {
                                        ...it,
                                        time_slot_for: arr4,
                                      };
                                    });
                                    settimeSlotArr(arr3);
                                    arr.splice(index, 1);
                                    setFieldValue("adult_price", "");

                                    setFieldValue(`class_type`, arr);
                                  }
                                }}
                                checked={values?.class_type?.includes("adult")}
                                onBlur={handleBlur}
                              />
                              <div className="div-bg">
                                <p>
                                  <span>adult</span>
                                </p>
                              </div>
                            </label>
                          </div>
                        </div>
                      </div>
                      {values.class_type?.includes("child") && (
                        <div className="col-sm-6 col-md-6 mb-4">
                          <label className="fw-bold">Child Price</label>
                          <div className="form-group">
                            <div className="input-container">
                              <input
                                type="number"
                                className="form-control pl-5"
                                placeholder="Child Price"
                                name="child_price"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.child_price}
                              />
                              <img
                                src="assets/images/price.png"
                                className="input-img"
                                alt=""
                              />
                            </div>
                            {errors.child_price && touched.child_price && (
                              <div className="text-red">
                                {errors.child_price}
                              </div>
                            )}
                          </div>
                        </div>
                      )}
                      {values.class_type?.includes("adult") && (
                        <div className="col-sm-6 col-md-6 mb-4">
                          <label className="fw-bold">Adult Price</label>
                          <div className="form-group">
                            <div className="input-container">
                              <input
                                className="form-control pl-5"
                                placeholder="Adult Price"
                                name="adult_price"
                                type="number"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.adult_price}
                              />
                              <img
                                src="assets/images/price.png"
                                className="input-img"
                                alt=""
                              />
                            </div>
                            {errors.adult_price && touched.adult_price && (
                              <div className="text-red">
                                {errors.adult_price}
                              </div>
                            )}
                          </div>
                        </div>
                      )}
                      <div className="col-sm-6 col-md-6 mb-4">
                        <div className="form-group">
                          <label className="fw-bold">Area Name</label>
                          {errors.areacode && touched.areacode && (
                            <span className="text-red ms-1">
                              {errors.areacode}
                            </span>
                          )}
                          <div className="input-container">
                            <select
                              className="form-control pl-5 form-select"
                              name={`areacode`}
                              onChange={(e) => {
                                setFieldValue(`areacode`, e.target.value);
                                setAreacode(e.target.value);
                              }}
                              value={values?.areacode}
                            >
                              <option value="">Area</option>
                              {areaList?.map((item, i) => (
                                <option value={item?.id} key={i}>
                                  {item?.areacode}
                                </option>
                              ))}
                            </select>
                            <img
                              src="assets/images/address.png"
                              className="input-img"
                              alt=""
                            />
                          </div>
                        </div>
                      </div>{" "}
                      {values.areacode && (
                        <div className="col-sm-6 col-md-6 mb-4">
                          <label className="fw-bold">Venue</label>
                          <div className="form-group">
                            <div className="input-container">
                              <div className="">
                                <select
                                  className="form-control form-select pl-5"
                                  value={values.schoolId}
                                  onChange={(e) => {
                                    setFieldValue(
                                      "schoolId",
                                      e.target.value.toString()
                                    );
                                  }}
                                >
                                  <option value="">Select Venue</option>
                                  {Schoollist?.map((item, i) => (
                                    <option value={item?.id} key={i}>
                                      {item?.name}
                                    </option>
                                  ))}
                                </select>
                              </div>
                              <img
                                src="assets/images/address.png"
                                className="input-img"
                                alt=""
                              />
                            </div>
                            {errors.schoolId && touched.schoolId && (
                              <div className="text-red">{errors.schoolId}</div>
                            )}
                          </div>
                        </div>
                      )}
                      <div className="col-sm-6 col-md-6 col-lg-6 mb-4">
                        <label className="fw-bold">Teacher</label>
                        <div className="form-group">
                          <div className="input-container">
                            <select
                              className="form-control"
                              value={values.teachers}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              name="teachers"
                            >
                              <option>Select Teacher</option>
                              {teacherList?.map((it) => {
                                return (
                                  <option value={it?.id} key={it?.id}>
                                    {it.full_name}
                                  </option>
                                );
                              })}
                            </select>
                          </div>
                          {errors?.teachers && touched?.teachers && (
                            <div className="text-red">{errors?.teachers}</div>
                          )}
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-6 col-lg-6 mb-4">
                        <label className="fw-bold">Discipline</label>
                        <div className="form-group">
                          <div className="input-container">
                            <div className="checkboxs AddStdtCheck">
                              {disciplines?.map((disciplin, ind) => {
                                return (
                                  <label key={ind}>
                                    <input
                                      type="radio"
                                      value={disciplin?.id}
                                      name="discipline"
                                      checked={
                                        values.discipline.toString() ===
                                        disciplin?.id?.toString()
                                      }
                                      onChange={(e) => {
                                        setFieldValue(
                                          "discipline",
                                          e.target.value
                                        );
                                      }}
                                    />
                                    <div className="div-bg">
                                      <p>
                                        <span>{disciplin?.name}</span>
                                      </p>
                                    </div>
                                  </label>
                                );
                              })}

                              {/* <label>
                                <input
                                  type="radio"
                                  value="kick boxing"
                                  name="discipline"
                                  checked={values.discipline === "kick boxing"}
                                  onChange={() => {
                                    setFieldValue("discipline", "kick boxing");
                                  }}
                                />
                                <div className="div-bg">
                                  <p>
                                    <span>kick boxing</span>
                                  </p>
                                </div>
                              </label> */}
                            </div>
                          </div>
                        </div>
                        {errors.discipline && touched.discipline && (
                          <div className="text-red">{errors.discipline}</div>
                        )}
                      </div>
                      <div className="col-sm-12 col-md-12 col-lg-12 mb-3">
                        <label className="fw-bold">Add Time Slots</label>
                        {error && <div className="text-red">{error}</div>}
                      </div>
                      {timeSlotArr?.map((item, i) => {
                        return (
                          <div key={i} className="row">
                            <div className="col-sm-6 col-md-3 col-lg-2 mb-4">
                              <div className="form-group">
                                <label>Start Date</label>
                                <div className="input-container">
                                  <DatePicker
                                    containerClassName="form-control pl-5"
                                    value={item?.start_date}
                                    name={`timeSlotArr${i}?.start_date`}
                                    onChange={(val) => {
                                      let arr = [...timeSlotArr];
                                      arr[i].start_date = val.toDate();
                                      settimeSlotArr(arr);
                                    }}
                                    // disabled={id && id}
                                    placeholder="Start Date"
                                    inputclassName="dateInputClass"
                                    minDate={new Date()}
                                  />
                                  <img
                                    src="assets/images/Date-of-Birth.png"
                                    className="input-img"
                                    alt=""
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-sm-6 col-md-3 col-lg-2 mb-4">
                              <div className="form-group">
                                <label>End Date</label>
                                <div className="input-container">
                                  <DatePicker
                                    containerClassName="form-control pl-5"
                                    value={item?.end_date}
                                    name={`timeSlotArr${i}?.date`}
                                    onChange={(val) => {
                                      let arr = [...timeSlotArr];
                                      arr[i].end_date = val.toDate();
                                      settimeSlotArr(arr);
                                    }}
                                    // disabled={id && id}
                                    placeholder="Class Date"
                                    inputclassName="dateInputClass"
                                    minDate={item?.start_date}
                                  />
                                  <img
                                    src="assets/images/Date-of-Birth.png"
                                    className="input-img"
                                    alt=""
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-sm-6 col-md-3 col-lg-3 mb-4">
                              <div className="form-group">
                                <label>Days</label>
                                <div className="input-container">
                                  <MultiSelect
                                    // disabled={id && id}
                                    className="form-control"
                                    options={options}
                                    hasSelectAll={false}
                                    value={item.days || []}
                                    onChange={(val) => {
                                      let arr = [...timeSlotArr];
                                      arr[i].days = val;
                                      settimeSlotArr(arr);
                                    }}
                                    labelledBy="Select"
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-sm-6 col-md-3 col-lg-2 mb-4">
                              <div className="form-group">
                                <label>Start Time</label>
                                <div className="input-container">
                                  <DatePicker
                                    containerClassName="form-control pl-5"
                                    disableDayPicker
                                    format="hh:mm A"
                                    plugins={[<TimePicker hideSeconds />]}
                                    inputclassName="dateInputClass"
                                    name={`timeSlotArr${i}.start_time`}
                                    placeholder="Event Time"
                                    value={item?.start_time}
                                    onChange={(val) => {
                                      console.log(val, "Time picker");
                                      let arr = [...timeSlotArr];
                                      arr[i].start_time = val.toDate();
                                      settimeSlotArr(arr);
                                    }}
                                  />
                                  <img
                                    src="assets/images/time.png"
                                    className="input-img"
                                    alt=""
                                  />
                                </div>
                                {/* {errors?.time_slots &&
                                touched?.time_slots[i]?.time && (
                                  <div className="text-red">
                                    {errors?.time_slots[i]?.time}
                                  </div>
                                )} */}
                              </div>
                            </div>
                            <div className="col-sm-6 col-md-3 col-lg-2 mb-4">
                              <div className="form-group">
                                <label>End Time</label>
                                <div className="input-container">
                                  <DatePicker
                                    containerClassName="form-control pl-5"
                                    disableDayPicker
                                    format="hh:mm A"
                                    plugins={[<TimePicker hideSeconds />]}
                                    inputclassName="dateInputClass"
                                    name={`timeSlotArr${i}.end_time`}
                                    placeholder="Event Time"
                                    value={item?.end_time}
                                    onChange={(val) => {
                                      let arr = [...timeSlotArr];
                                      arr[i].end_time = val.toDate();
                                      settimeSlotArr(arr);
                                    }}
                                  />
                                  <img
                                    src="assets/images/time.png"
                                    className="input-img"
                                    alt=""
                                  />
                                </div>
                                {/* {errors?.time_slots &&
                                touched?.time_slots[i]?.time && (
                                  <div className="text-red">
                                    {errors?.time_slots[i]?.time}
                                  </div>
                                )} */}
                              </div>
                            </div>
                            <div className="form-group col-sm-6 col-md-3 col-lg-3 mb-4 position-relative">
                              <label></label>
                              <div className="checkboxs AddStdtCheck">
                                <label>
                                  <input
                                    type="checkbox"
                                    checked={item.time_slot_for.includes(
                                      "adult"
                                    )}
                                    disabled={
                                      (values.class_type.length === 1 &&
                                      values.class_type?.[0] === "child"
                                        ? true
                                        : false) || id
                                    }
                                    name={`timeSlotArr.${i}.time_slot_for`}
                                    onChange={(e) => {
                                      let checked = e.target.checked;
                                      console.log(checked);
                                      let arr = [...timeSlotArr];
                                      const ind =
                                        arr[i].time_slot_for?.indexOf("adult");
                                      if (checked) {
                                        if (ind === -1) {
                                          arr[i].time_slot_for?.push("adult");
                                        }
                                      } else if (!checked) {
                                        if (ind !== -1) {
                                          arr[i].time_slot_for?.splice(ind, 1);
                                        }
                                      }
                                      settimeSlotArr(arr);
                                    }}
                                  />
                                  <div className="div-bg">
                                    <p>
                                      <span>Adult</span>
                                    </p>
                                  </div>
                                </label>

                                <label>
                                  <input
                                    type="checkbox"
                                    //value="child"
                                    checked={item?.time_slot_for.includes(
                                      "child"
                                    )}
                                    disabled={
                                      (values.class_type.length === 1 &&
                                      values.class_type?.[0] === "adult"
                                        ? true
                                        : false) || id
                                    }
                                    name={`timeSlotArr.${i}.time_slot_for`}
                                    onChange={(e) => {
                                      let checked = e.target.checked;
                                      let arr = [...timeSlotArr];
                                      const ind =
                                        arr[i].time_slot_for?.indexOf("child");
                                      if (checked) {
                                        if (ind === -1) {
                                          arr[i].time_slot_for?.push("child");
                                        }
                                      } else if (!checked) {
                                        if (ind !== -1) {
                                          arr[i].time_slot_for?.splice(ind, 1);
                                        }
                                      }
                                      settimeSlotArr(arr);
                                    }}
                                  />
                                  <div className="div-bg">
                                    <p>
                                      <span>Child</span>
                                    </p>
                                  </div>
                                </label>
                              </div>
                              {/* {errors?.time_slots &&
                              touched?.time_slots[i]?.time_slot_for && (
                                <span className="text-danger">
                                  {errors?.time_slots[i]?.time_slot_for}
                                </span>
                              )} */}
                              <div className="addClass ">
                                <span
                                  className="add-new deleteClass"
                                  onClick={() => {
                                    let arr = [...timeSlotArr];
                                    arr.splice(i, 1);
                                    settimeSlotArr(arr);
                                  }}
                                >
                                  x
                                </span>
                              </div>
                            </div>
                            {timeSlotArr.length > 1 && <hr />}
                          </div>
                        );
                      })}
                      <div className="col-sm-12 addClass text-end">
                        <span
                          className="add-new"
                          onClick={() => {
                            let arr = [...timeSlotArr];
                            arr.push({
                              start_date: new Date(),
                              end_date: new Date(),
                              time: "",
                              days: [],
                              time_slot_for: [],
                              time_slot_id: "",
                            });
                            settimeSlotArr(arr);
                          }}
                        >
                          +
                        </span>
                      </div>
                      <div className="col-sm-12 col-md-12 mt-4 mb-2">
                        <div className="form-group">
                          <label className="fw-bold">Holidays</label>
                          <div className="input-container">
                            <DatePicker
                              multiple
                              containerClassName="form-control pl-5"
                              value={holidays}
                              onChange={(val) => {
                                console.log(val, "Holiday after change");
                                let arr = val.map((item) => item.toDate());
                                setHolidays(arr);
                              }}
                              // disabled={id && id}
                            />
                            <img
                              src="assets/images/Date-of-Birth.png"
                              className="input-img"
                              alt=""
                            />
                          </div>
                        </div>
                      </div>
                      {holidays?.length > 0 && (
                        <div className="col-sm-12 col-md-12 mt-4 mb-2 d-grid gridLayoutColumn">
                          {holidays?.map((item, i) => {
                            return (
                              <li key={i}>
                                {moment(item).format("DD-MM-YYYY")}
                              </li>
                            );
                          })}
                        </div>
                      )}
                      <div className="col-sm-12 col-md-12 mt-4 mb-2">
                        <div className="form-group">
                          <h5>About</h5>
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-12 mb-4">
                        <div className="form-group">
                          <textarea
                            className="textarea-control p-3"
                            rows="7"
                            placeholder="Write here..."
                            name="about"
                            {...getFieldProps("about")}
                          ></textarea>
                        </div>
                        {errors.about && touched.about && (
                          <div className="text-red">{errors.about}</div>
                        )}
                      </div>
                      <div className="col-sm-12 col-md-12 text-center">
                        <button
                          type="submit"
                          className="btn btn-save"
                          disabled={submitting}
                        >
                          {submitting ? <Loader /> : "Save"}
                        </button>
                      </div>
                    </div>
                  </form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </article>
    );
  }
}

export default AddClass;
