import moment from "moment";
import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { deleteEvent } from "../../api/event";
import DeleteModal from "../../component/Modal/Delete";
import Paginations from "../../component/Pagination";
import { variants } from "../../constant/Variants";
import { SchoolListReducer } from "../slice";
import {
  fetchEventList,
  getActiveCount,
  getInActiveCount,
  getTotalCount,
} from "./EventSlice";

function Events() {
  const dispatch = useDispatch();
  const list = useSelector((state) => state.event.list);
  const active = useSelector(getActiveCount);
  const inactive = useSelector(getInActiveCount);
  const total = useSelector(getTotalCount);
  const [search, setSearch] = useState("");
  const [show, setShow] = useState(false);
  const [delId, setDelId] = useState("");
  const [page, setPage] = useState(1);
  const schoolList = useSelector((state) => state.main.schools);
  const [status, setStatus] = useState(1);

  const handleShow = () => {
    setShow(!show);
  };

  const getList = useCallback(() => {
    dispatch(
      fetchEventList({ page, limit: 10, search: search, status: status })
    );
  }, [dispatch, page, search, status]);

  useEffect(() => {
    getList();
    dispatch(SchoolListReducer({ page: 1, limit: 10000 }));
  }, [getList, dispatch]);

  const confirmDelete = () => {
    deleteEvent({ event_id: delId }).then((res) => {
      if (res?.code === 1) {
        toast.success(res?.message);
        handleShow();
        getList();
      } else {
        toast.error(res?.message);
      }
    });
  };
  return (
    <article className="col-lg-9 articleClass">
      <div className="article-body store-bd-gy">
        <div className="row align-items-center col-rever">
          <div className="col-sm-12 col-md-6 col-lg-6 mb-3">
            <div className="link-active shadow">
              <p>
                <img src="assets/images/event-calender-selected.png" alt="" />{" "}
                events {status === 1 ? total?.active : total?.inactive}
              </p>
            </div>
          </div>
          <div className="col-sm-12 col-md-6 col-lg-6 mb-3">
            <div className="d-flex align-items-center lg-justify-content-end">
              <form className="has-search">
                <span className="form-search-icon form-control-feedback"></span>
                <input
                  type="text"
                  className="form-control shadow"
                  placeholder="Search event by name"
                  value={search}
                  onChange={(e) => {
                    setPage(1);

                    setSearch(e.target.value);
                  }}
                />
              </form>
              <Link to={`/add-event`} className="">
                <img
                  src="assets/images/addBtn.png"
                  alt=""
                  className="add-new"
                />
              </Link>
            </div>
          </div>
        </div>
        <div className="col-sm-12 col-md-12 col-lg-6">
          <ul className="nav nav-pills group-nav mb-1 mt-2">
            <li className="nav-item">
              <button
                className={status === 1 ? "nav-link active" : "nav-link"}
                id="pills-new-tab"
                type="button"
                onClick={() => {
                  setStatus(1);
                  setPage(1);
                }}
              >
                Active
              </button>
            </li>
            <li className="nav-item">
              <button
                className={status === 0 ? "nav-link active" : "nav-link"}
                id="pills-accepted-tab"
                type="button"
                onClick={() => {
                  setStatus(0);
                  setPage(1);
                }}
              >
                Inactive
              </button>
            </li>
          </ul>
        </div>
        <div className="row mt-3">
          <div className="col-sm-12">
            <div className="table-responsive text-center">
              <table className="table storetable">
                <thead className="text-center">
                  <tr>
                    <th scope="col">ID</th>
                    <th scope="col">Photo</th>
                    <th scope="col">Event name</th>
                    <th scope="col">Start Date</th>
                    <th scope="col">Time</th>
                    <th scope="col">Area</th>
                    <th scope="col">Type</th>
                    <th scope="col">Discipline</th>
                    <th scope="col">Membership</th>
                    <th scope="col">Levels</th>
                    <th scope="col">Location</th>
                    <th scope="col">Action</th>
                  </tr>
                  <tr className="border-0 bg-none invisible">
                    <td className="border-0 bg-none"></td>
                  </tr>
                </thead>
                <tbody>
                  {list?.length > 0 ? (
                    list?.map((item, i) => {
                      return (
                        <tr key={i}>
                          <td>{item?.id || "NA"}</td>
                          <td>
                            <img
                              src={
                                item?.image?.includes("http")
                                  ? item?.image
                                  : "assets/images/headerlogo.png"
                              }
                              className="table-img"
                              alt=""
                            />
                          </td>
                          <td>{item?.name || "NA"}</td>
                          <td>
                            {moment(item?.date).format("DD MMM YYYY") || "NA"}
                          </td>
                          <td>
                            {item?.start_time &&
                            item?.start_time !== "0000-00-00"
                              ? moment(item?.start_time, "hh:mm:ss").format(
                                  "hh:mm a"
                                )
                              : "NA"}
                          </td>
                          <td>{item?.areacode_details?.areacode || "NA"}</td>
                          <td>
                            {/* <i className="fa fa-gbp" aria-hidden="true"></i> 35 */}
                            {item?.event_types_details
                              ? item?.event_types_details?.name
                              : "NA"}
                          </td>
                          <td>{item?.discipline_details?.name || "NA"}</td>
                          <td>
                            {item?.age_group?.toLowerCase() === "both"
                              ? "Adult | Child"
                              : item?.age_group?.charAt(0)?.toUpperCase() +
                                  item?.age_group?.slice(
                                    1,
                                    item?.age_group?.length + 1
                                  ) || "NA"}
                          </td>

                          {/* {item?.levels?.length > 0
                              ? item?.levels?.length
                              : "0"} */}
                          {/* <hr /> */}
                          {item?.levels?.length > 0 ? (
                            <td className="text-start">
                              <ol>
                                {item?.levels?.map((lvl, i) => {
                                  return <li>{lvl?.name}</li>;
                                })}
                              </ol>
                            </td>
                          ) : (
                            <td>NA</td>
                          )}

                          <td>
                            {schoolList?.find(
                              (school) => school.id === item.schoolId
                            )?.name || "NA"}
                          </td>
                          <td>
                            <div className="d-flex justify-content-center action">
                              <button className="btn" title="View">
                                <Link to={`/event/${item?.id}`}>
                                  <img src="assets/images/view.png" alt="" />
                                </Link>
                              </button>
                              <button className="btn" title="Edit">
                                <Link to={`/edit-event/${item?.id}`}>
                                  <img src="assets/images/edit.png" alt="" />
                                </Link>
                              </button>
                              <button
                                className="btn"
                                onClick={() => {
                                  setDelId(item.id);
                                  handleShow();
                                }}
                                title="Delete"
                              >
                                <img src="assets/images/delete.png" alt="" />
                              </button>
                              {/* <button className="btn">
                                <input
                                  type="checkbox"
                                  className="toggle"
                                  checked=""
                                />
                              </button> */}
                            </div>
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan={12}>No data found.</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            {status === 1
              ? total?.active > 10
              : total?.inactive > 10 && (
                  <Paginations
                    handlePage={setPage}
                    page={page}
                    total={status === 1 ? total?.active : total?.inactive}
                  />
                )}
          </div>
        </div>
      </div>

      <DeleteModal
        page="Event"
        show={show}
        handleShow={handleShow}
        cancel={() => {
          setShow(false);
        }}
        confirmDelete={confirmDelete}
      />
    </article>
  );
}

export default Events;
