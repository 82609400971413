import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useNavigate, useParams } from "react-router";
import { details, fetchEventDetail } from "./EventSlice";
import moment from "moment";
import { SchoolListReducer } from "../slice";
import Loader from "../../component/Loader";
import { changeLevel, changeRegisterFor } from "../register/RegisterSlice";
import BackButton from "./../../component/BackButton";

function ViewEvent() {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const detail = useSelector((state) => state.event.detail);
  const [loading, setLoading] = useState(false);
  const schoolList = useSelector((state) => state.main.schools);

  useEffect(() => {
    setLoading(true);
    dispatch(fetchEventDetail(id));
    dispatch(SchoolListReducer({ page: 1, limit: 10000 }));
    setLoading(false);
  }, [dispatch, id]);

  useEffect(() => {
    return () => {
      dispatch(details(null));
    };
  }, [dispatch]);

  if (detail === null) {
    return (
      <div className="col-sm-12">
        <Loader />;
      </div>
    );
  } else {
    return (
      <article className="col-lg-9 articleClass">
        <div className="article-body store-bd-gy">
          <div className="row align-items-center col-rever">
            <BackButton />

            <div className="col-sm-12 col-md-12 col-lg-12 mb-3 text-center">
              <h3>Event Calendar Details</h3>
            </div>
          </div>
          <div className="row mt-5 px-md-5">
            <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-5">
              <div className="bg-white p-4 rounded h-100 shadow position-relative">
                <div className="row">
                  <div className="col-12 col-sm-10 col-md-10 col-lg-10">
                    <div className="event-box-flex align-items-normal">
                      {!detail?.levels?.length > 0 &&
                      detail?.age_group?.toLowerCase() === "child" ? (
                        <span className="event-price">
                          <i className="fa fa-gbp" aria-hidden="true"></i>{" "}
                          {detail?.child_price || 0}
                        </span>
                      ) : !detail?.levels?.length > 0 &&
                        detail?.age_group?.toLowerCase() === "adult" ? (
                        <span className="event-price">
                          <i className="fa fa-gbp" aria-hidden="true"></i>{" "}
                          {detail?.adult_price || 0}
                        </span>
                      ) : !detail?.levels?.length > 0 &&
                        detail?.age_group?.toLowerCase() === "both" ? (
                        <span className="event-price">
                          <i className="fa fa-gbp" aria-hidden="true"></i>{" "}
                          {detail?.child_price || 0}
                        </span>
                      ) : null}
                      <span>
                        <img
                          src={
                            detail?.image?.includes("http")
                              ? detail?.image
                              : "assets/images/kick.png"
                          }
                          className="event-img"
                          alt=""
                        />
                      </span>
                      <span>
                        <h4>{detail?.name || "NA"}</h4>
                        <p className="mb-0 d-flex flex-wrap gap-row-col">
                          <span title="Event Date">
                            <img
                              src="assets/images/Date-of-Birth.png"
                              className="evt-dtl-icon"
                              alt=""
                            />{" "}
                            {moment(detail?.date).format("DD MMM YYYY")}
                          </span>
                          <span title="Event Start Time">
                            <img
                              src="assets/images/time.png"
                              className="evt-dtl-icon"
                              alt=""
                            />{" "}
                            {moment(detail?.start_time, "HH:mm:ss").format(
                              "hh:mm a"
                            )}
                          </span>
                          <span title="Event Venue">
                            <img
                              src="assets/images/address.png"
                              className="evt-dtl-icon"
                              alt=""
                            />
                            {schoolList?.find(
                              (school) => school?.id === detail?.schoolId
                            )?.name +
                              `, ${detail?.areacode_details?.areacode}` || "NA"}
                          </span>
                          <span title="Event Type">
                            <img
                              src="assets/images/total-seats.png"
                              className="evt-dtl-icon"
                              alt=""
                            />
                            {detail?.event_types_details?.name || "NA"}
                          </span>
                          <span title="Event Levels">
                            <img
                              src="assets/images/total-seats.png"
                              className="evt-dtl-icon"
                              alt=""
                            />
                            {detail?.age_group.toLowerCase() === "both"
                              ? `Adult | Child`
                              : detail?.age_group}
                          </span>
                          {detail?.discipline_details?.name && (
                            <span title="Event Levels">
                              <img
                                src="assets/images/total-seats.png"
                                className="evt-dtl-icon"
                                alt=""
                              />
                              {detail?.discipline_details?.name || ""}
                            </span>
                          )}
                        </p>
                      </span>
                    </div>
                  </div>
                  {detail?.levels?.length > 0 && (
                    <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 my-3">
                      <label className="fw-bold lead">Levels</label>
                    </div>
                  )}

                  {detail?.levels?.map((item, i) => {
                    return (
                      <>
                        <div className="col-sm-12 col-md-12 col-lg-9 col-xl-9">
                          <div className="py-2 position-relative mb-1" key={i}>
                            <span className="fw-bold">Level: {item?.name}</span>
                            <p className="mb-0">
                              <img
                                src="assets/images/time.png"
                                className="evt-dtl-icon"
                                alt=""
                              />{" "}
                              {moment(item?.time, "HH:mm:ss").format("hh:mm a")}
                            </p>
                            <p className="mt-2 pb-0 mb-0">{item?.note}</p>
                          </div>
                        </div>
                        <div className="col-sm-12 col-md-12 col-lg-3 col-xl-3">
                          <div className="d-flex justify-content-center align-items-center gap-2">
                            {item?.child_price !== "0" && item?.child_price && (
                              <span
                                className="event-price custom-event-price"
                                style={{ fontSize: "20px" }}
                              >
                                Child:{" "}
                                <i className="fa fa-gbp" aria-hidden="true"></i>{" "}
                                {item.child_price}
                              </span>
                            )}
                            {item?.adult_price !== "0" && item?.adult_price && (
                              <span
                                className="event-price custom-event-price"
                                style={{ fontSize: "20px" }}
                              >
                                Adult:{" "}
                                <i className="fa fa-gbp" aria-hidden="true"></i>{" "}
                                {item.adult_price}
                              </span>
                            )}
                          </div>
                        </div>
                      </>
                    );
                  })}
                </div>
              </div>
            </div>

            <div
              className="col-sm-12 text-center"
              onClick={() => {
                (async () => {
                  await localStorage.setItem("register_for", "event");
                })();
                dispatch(changeRegisterFor("event"));
                if (detail?.levels?.length > 0) {
                  (async () => {
                    await localStorage.setItem(
                      "level",
                      detail?.levels?.[0]?.id
                    );
                  })();
                  dispatch(changeLevel(detail?.levels?.[0]?.id));
                }
                setTimeout(() => {
                  navigate(`/register/${id}`);
                }, 500);
              }}
            >
              {" "}
              <button className="btn btn-save ">View Register</button>
            </div>
          </div>
        </div>
      </article>
    );
  }
}

export default ViewEvent;
