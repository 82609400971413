import * as Yup from "yup";

export const addUserSchema = Yup.object({
  first_name: Yup.string().required("Required"),
  last_name: Yup.string().required("Required"),
  username: Yup.string().required("Required"),

  email: Yup.string().email("Kindly enter a valid email.").required("Required"),
  password: Yup.string()
    .matches(
      // eslint-disable-next-line no-useless-escape
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\.£€\$%\^&\*])(?=.{8,})/,
      "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
    )
    .required("Required"),
  mobile: Yup.string()
    .typeError("Kindly enter a valid number.")
    .required("Required"),
  address: Yup.object({
    property_number: Yup.string().required("Required"),
    street_number: Yup.string().required("Required"),
    town: Yup.string().required("Required"),
    country: Yup.string().required("Required"),
    postal_code: Yup.string().required("Required"),

    dob_year: Yup.string().required("Required"),
    dob_month: Yup.string().required("Required"),
    dob_day: Yup.string().required("Required"),
  }),
  students: Yup.array().of(
    Yup.object({
      first_name: Yup.string().required("Required"),
      last_name: Yup.string().required("Required"),
      dob_year: Yup.string().required("Required"),
      dob_month: Yup.string().required("Required"),
      dob_day: Yup.string().required("Required"),
      student_type: Yup.string(),
      discipline: Yup.string()
        // .oneOf(["Karate", "kick-boxing"], null)
        .required("Required"),
      emergency_mobile: Yup.string().notRequired().nullable().optional(),

      medical_note: Yup.string().notRequired().optional().nullable(),
      image: Yup.string().notRequired(),
      schoolId: Yup.string().required("Required"),
      areacode: Yup.string().required("Required"),
      trialAccountStatus: Yup.string().required("Required"),
      membership_level: Yup.object().when("trialAccountStatus", {
        is: (value) => value === "3" || value === undefined,
        then: Yup.object().typeError("Required").required("Required"),
      }),
      gender: Yup.string().required("Required"),
    })
  ),
  user_consents: Yup.array().min(1, "Kindly fill all the fields."),
});
export const editUserSchema = Yup.object({
  first_name: Yup.string().required("Required"),
  last_name: Yup.string().required("Required"),
  username: Yup.string().required("Required"),

  email: Yup.string().email("Kindly enter a valid email.").required("Required"),
  password: Yup.string().matches(
    // eslint-disable-next-line no-useless-escape
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\.£€\$%\^&\*])(?=.{8,})/,
    "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
  ),

  mobile: Yup.string()
    .typeError("Kindly enter a valid number.")
    .required("Required"),
  address: Yup.object({
    property_number: Yup.string().required("Required"),
    street_number: Yup.string().required("Required"),
    town: Yup.string().required("Required"),
    country: Yup.string().required("Required"),
    postal_code: Yup.string().required("Required"),

    dob_year: Yup.string().required("Required"),
    dob_month: Yup.string().required("Required"),
    dob_day: Yup.string().required("Required"),
  }),
  students: Yup.array().of(
    Yup.object({
      first_name: Yup.string().required("Required"),
      last_name: Yup.string().required("Required"),
      dob_year: Yup.string().required("Required"),
      dob_month: Yup.string().required("Required"),
      dob_day: Yup.string().required("Required"),
      student_type: Yup.string(),
      discipline: Yup.string().required("Required"),
      emergency_mobile: Yup.string().notRequired().nullable().optional(),

      medical_note: Yup.string().notRequired().optional().nullable(),
      image: Yup.string().notRequired(),
      schoolId: Yup.string().required("Required"),
      areacode: Yup.string().required("Required"),
      trialAccountStatus: Yup.string().required("Required"),
      membership_level: Yup.object().when("trialAccountStatus", {
        is: (value) => value === "3" || value === undefined,
        then: Yup.object().typeError("Required").required("Required"),
      }),
      gender: Yup.string().required("Required"),
      membership_year: Yup.string(),
      membership_month: Yup.string(),
      membership_day: Yup.string(),
    })
  ),
  user_consents: Yup.array().of(
    Yup.object({
      consent: Yup.string(),
      value: Yup.string().required("Required"),
      note: Yup.string(),
    })
  ),
});
